// icons
import { Box, Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
import { OfferProps } from 'src/@types/offer';
import { ServiceProps } from 'src/@types/service';
import Image from 'src/components/NextSanityImage';
// components
import { BgOverlay, FabButtonAnimate, MotionViewport, TextMaxLine, varFade } from '../../components';
import { varHover, varTranHover } from '../../components/animate';
// hooks
import { useResponsive } from '../../hooks';
// routes
import Routes from '../../routes';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  services: ServiceProps[];
  offers: OfferProps[];
};

export default function LandingOffers({ offers, services }: Props) {
  const isDesktop = useResponsive('up', 'md');

  const getData = (index: number) => {
    const item = offers[index];

    return {
      slug: item.slug,
      title: item.title,
      header: item.header,
      description: item.description,
      color: item.color,
    };
  };

  const getServiceData = (index: number) => {
    const item = services[index];
    return {
      slug: item.slug,
      title: item.title,
      header: item.header,
      description: item.description,
    };
  };

  return (
    <RootStyle>
      <Container>
        <Stack
          sx={{
            textAlign: { xs: 'center', md: 'unset' },
          }}
          component={MotionViewport}
        >
          <Typography variants={varFade().inUp} component={m.h2} variant="h2">Aktuelle kampanjer</Typography>
        </Stack>

        <Grid container spacing={3} alignItems="center" sx={{ py: { xs: 8, md: 15 } }}>
          {/* Item 1 */}
          {isDesktop && (
            <Grid item xs={12} sm={6} md={2}>
              <SmallItem {...getServiceData(0)} isDesktop={isDesktop} />
            </Grid>
          )}
          {/* 
          {!isDesktop && (
            <Grid item xs={12} sm={6} md={2}>
              <SmallItem {...getServiceData(5)} isDesktop={isDesktop} />
            </Grid>
          )} */}

          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              {/* Item 2 */}
              <Grid item xs={12} sm={6} md={9}>
                <LargeItem {...getData(0)} isDesktop={isDesktop} />
              </Grid>

              {/* Item 3 */}
              {isDesktop && (
                <Grid item xs={12} sm={6} md={3}>
                  <Stack justifyContent={{ md: 'flex-end' }} sx={{ height: { md: 1 } }}>
                    <SmallItem {...getServiceData(2)} isSquare isDesktop={isDesktop} />
                  </Stack>
                </Grid>
              )}

              {/* Item 4 */}
              {isDesktop && (
                <Grid item xs={12} sm={6} md={3}>
                  <SmallItem {...getServiceData(3)} isSquare isDesktop={isDesktop} />
                </Grid>
              )}
              {/* Item 5 */}
              <Grid item xs={12} sm={6} md={9}>
                <LargeItem {...getData(1)} isDesktop={isDesktop} />
              </Grid>
            </Grid>
          </Grid>

          {/* Item 6 */}
          {isDesktop && (
            <Grid item xs={12} sm={6} md={2}>
              <SmallItem {...getServiceData(5)} isDesktop={isDesktop} />
            </Grid>
          )}
        </Grid>

        <Stack alignItems={{ xs: 'center', md: 'flex-end' }}>
          <NextLink href={Routes.offers} passHref>
            <Button size="large" variant="contained">
              Se alle kampanjer
            </Button>
          </NextLink>
        </Stack>
      </Container>
    </RootStyle>
  );
}

interface LargeItemProps
  extends Pick<
  OfferProps,
  'slug' | 'header' | 'title' | 'description' | 'color'
  > {
  isDesktop?: boolean;
}

// ----------------------------------------------------------------------

function LargeItem({
  slug,
  header,
  title,
  description,
  color,
  isDesktop,
}: LargeItemProps) {
  return (
    <Paper
      sx={{
        borderRadius: 2,
        boxShadow: (theme) => theme.customShadows.z24,
        bgcolor: color?.value,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box position="relative" display="flex" width={1} height={1} sx={{ overflow: "hidden", padding: 1 }}>
            <Image
              alt={slug.current}
              src={header}
              ratio={isDesktop ? '3/4' : '6/4'}
              sx={{ borderRadius: 2, width: 1 }}
              objectPosition="right"
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            alignItems="flex-end"
            justifyContent="space-between"
            spacing={{ xs: 2, md: 0 }}
            sx={{ p: 4, pt: 5, height: 1 }}
          >
            <div>
              <Typography variant="h4" sx={{ mt: 1, mb: 2, color: 'primary.contrastText' }}>
                {title}
              </Typography>
              <TextMaxLine variant="body2" sx={{ color: 'primary.contrastText' }} line={3} gutterBottom>{description}</TextMaxLine>
            </div>

            <NextLink as={Routes.offer(slug.current)} href={Routes.offer('[slug]')}>
              <FabButtonAnimate
                sxWrap={{ width: '100%' }}
                sx={{ width: '100%', mt: 2 }}
                variant="extended"
                color="primary"
              >
                Se mer
              </FabButtonAnimate>
            </NextLink>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

// ----------------------------------------------------------------------

interface SmallItemProps extends Pick<ServiceProps, 'slug' | 'header' | 'title'> {
  isSquare?: boolean;
  isDesktop?: boolean;
}

function SmallItem({ slug, header, title, isSquare, isDesktop }: SmallItemProps) {
  return (
    <NextLink as={Routes.service(slug.current)} href={Routes.service('[slug]')}>
      <Paper
        component={m.div}
        whileHover="hover"
        sx={{ position: 'relative', cursor: 'pointer', borderRadius: 2, overflow: 'hidden' }}
      >
        <Stack
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 1,
            height: 1,
            zIndex: 9,
            position: 'absolute',
            color: 'common.white',
          }}
        >
          <Typography variant="h6" component="p">{title}</Typography>
        </Stack>
        <BgOverlay sx={{ opacity: 0.75 }} />

        <m.div variants={varHover(1.25)} transition={varTranHover()}>
          <Image
            alt={slug.current}
            src={header}
            ratio={(isSquare && '1/1') || (isDesktop && '3/4') || '1/1'}
          />
        </m.div>
      </Paper>
    </NextLink>
  );
}
