import { articles, offers, page as pageQuery, partners, services } from '@data/queries';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import sanity from '@utils/sanity';
import dynamic from 'next/dynamic';
import { ReactElement } from 'react';
import { ArticleProps } from 'src/@types/article';
import { OfferProps } from 'src/@types/offer';
import { PageProps } from 'src/@types/page';
import { PartnerProps } from 'src/@types/partner';
import { ServiceProps } from 'src/@types/service';
import Routes from 'src/routes';
import { LandingFeaturedServices, LandingHero } from 'src/sections/landing';
import LandingIntroduction from 'src/sections/landing/LandingIntroduction';
import LandingOffers2 from 'src/sections/landing/LandingOffers2';
import { Page } from '../src/components';
import Layout from '../src/layouts';
import NextLink from 'next/link';

const DynamicNewsletter = dynamic(() => import('src/sections/newsletter/Newsletter'));
const DynamicPosts = dynamic(() => import('src/sections/blog/BlogLatestPosts'));
const DynamicPartners = dynamic(() => import('src/sections/partners/Partners'));

// ----------------------------------------------------------------------

type Props = {
  data: {
    offers: OfferProps[];
    services: ServiceProps[];
    articles: ArticleProps[];
    partners: PartnerProps[];
  };
  page: PageProps;
};

export default function LandingPage({ page, data }: Props) {
  return (
    <Page seo={page.seo} canonical="https://www.b-e-a.no/">
      <LandingHero />

      <Box
        sx={{
          bgcolor: 'success.dark',
        }}
      >
        <Container>
          <Stack
            sx={{ py: 5, color: 'common.white' }}
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="space-between"
          >
            <div>
              <Typography variant="overline" sx={{ color: 'success.lighter' }}>
                Jobb hos BEA
              </Typography>
              <Typography variant="h3">Vi søker flere elektrikere</Typography>
            </div>
            <NextLink href="/om-oss/ledige-stillinger/vi-søker-flere-elektrikere/" passHref>
              <Button variant="contained" size="large" color="inherit">
                Se stillingsannonse
              </Button>
            </NextLink>
          </Stack>
        </Container>
      </Box>

      <LandingFeaturedServices services={data.services.slice(0, 4)} />

      <LandingOffers2 offers={data.offers} />

      <LandingIntroduction />

      <DynamicPartners partners={data.partners} />

      <DynamicPosts articles={data.articles} />

      <DynamicNewsletter />
    </Page>
  );
}

// ----------------------------------------------------------------------

LandingPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

// ----------------------------------------------------------------------

export async function getStaticProps() {
  const query = `
    {
      "articles": ${articles(5)},
      "services": ${services},
      "offers": ${offers(4)},
      "partners": ${partners}
    }
  `;

  const data = await sanity.fetch(query);
  const page = await sanity.fetch(pageQuery, { slug: Routes.home });

  return {
    props: { page, data }, // will be passed to the page component as props
  };
}
