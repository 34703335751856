// @mui
import { Box } from '@mui/material';
import { OfferProps } from 'src/@types/offer';
// components
//
import OfferItem from './OfferItem';

// ----------------------------------------------------------------------

type Props = {
  offers: OfferProps[];
};

export default function OfferItemList({ offers }: Props) {
  return (
    <>
      <Box
        sx={{
          pt: 8,
          display: 'grid',
          rowGap: { xs: 4, md: 5 },
          columnGap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          },
        }}
      >
        {offers.map((offer) =>
          <OfferItem key={offer._id} offer={offer} />
        )}
      </Box>
    </>
  );
}
