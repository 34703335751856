import playIcon from '@iconify/icons-carbon/play';
import { Box, BoxProps, Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import staticLoader from '@utils/staticLoader';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useState } from 'react';
import { Background, Parallax } from 'react-parallax';
import NextSanityImage from 'src/components/NextSanityImage';
import Routes from 'src/routes';
import { Iconify, PlayerWithButton } from '../../components';

export default function LandingHero() {
  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          textAlign: { xs: 'center', sm: 'left' },
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          overflow: 'hidden',
          bgcolor: 'grey.200',
        }}
      >
        <Container
          sx={{
            zIndex: 9,
            py: { xs: 10, md: 0 },
            pb: { xs: 3, md: 0 },
            pt: { xs: 3, md: 10 },
            mt: { xs: 12, md: 0 },
            position: { md: 'absolute' },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="overline" sx={{ mb: 1, color: 'text.secondary' }}>
                Bergen Elektro Automasjon
              </Typography>
              <Typography variant="h1" sx={{ mb: 4 }}>
                Møt den punktlige elektrikeren i Bergen.
              </Typography>

              <Stack
                spacing={{ xs: 2, sm: 2 }}
                alignItems={{ xs: 'stretch', sx: 'center' }}
                justifyContent={{ xs: 'center', sm: 'left' }}
                direction={{ xs: 'column', sm: 'row' }}
              >
                <NextLink href={Routes.form} passHref>
                  <Button size="large" variant="contained">
                    Få gratis befaring
                  </Button>
                </NextLink>
                <Button
                  endIcon={<Iconify icon={playIcon} />}
                  size="large"
                  color="inherit"
                  variant="outlined"
                  onClick={handleOpenVideo}
                >
                  Se video
                </Button>
              </Stack>

              <Box sx={{ mt: 4, mb: 4 }} />
              <Paper
                sx={{
                  display: { xs: 'none', md: 'block' },
                  bgcolor: 'grey.200',
                  p: 1.5,
                  width: 'max-content',
                  float: { xs: 'none', md: 'left' },
                  margin: { xs: '0 auto', md: 'none' },
                }}
              >
                <Badges />
              </Paper>
            </Grid>

            <Box
              sx={{
                top: 0,
                bottom: 0,
                right: { md: '-23%' },
                height: { xs: 300, sm: '80vh' },
                width: { xs: 1, sm: '70%' },
                position: 'absolute',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Parallax strength={300} style={{ width: '100%', height: '80vh' }}>
                <Background>
                  <Box width="70vw" height="80vh">
                    <NextSanityImage
                      src="image-bcdbffdb2a7485a20579b1152ea22e978dbf3674-3027x2357-jpg"
                      sizes="(max-width: 800px) 50vw, 800px"
                      alt="daglig-leder"
                      sx={{ height: 1 }}
                      priority
                    />
                  </Box>
                </Background>
              </Parallax>
            </Box>
          </Grid>
        </Container>
        <Box
          sx={{
            width: 1,
            height: 1,
            position: {
              xs: 'absolute',
              md: 'relative',
            },
          }}
        >
          <Box
            sx={{
              height: { xs: 1, md: '85vh' },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: 350,
          width: 1,
          position: 'relative',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <NextSanityImage
          src="image-bcdbffdb2a7485a20579b1152ea22e978dbf3674-3027x2357-jpg"
          sizes="(max-width: 800px) 50vw, 800px"
          alt="daglig-leder"
          sx={{ height: 1 }}
          priority
        />
      </Box>
      {/* <Divider /> */}

      <PlayerWithButton
        open={openVideo}
        onClose={handleCloseVideo}
        videoPath={'https://vimeo.com/287428521'}
      />
    </>
  );
}

const Badges = ({ sx }: BoxProps) => {
  return (
    <Stack
      spacing={3}
      direction="row"
      justifyContent={{ xs: 'center', sm: 'unset' }}
      alignItems="center"
      sx={{ maxWidth: 680, lineHeight: 0, ...sx }}
    >
      <a href="https://www.miljofyrtarn.no/" target="_blank" rel="noreferrer">
        <NextImage
          height={50}
          width={58.3}
          loader={staticLoader}
          src="/static/brands/miljofyrtarn.png"
          alt="Miljøfyrtårn logo"
        />
      </a>
      <a href="https://sgregister.dibk.no/enterprises/982743834" target="_blank" rel="noreferrer">
        <NextImage
          height={50}
          width={50}
          loader={staticLoader}
          src="/static/brands/sentralt_godkjent.jpg"
          alt="Logo Sentralt Godkjent"
        />
      </a>
    </Stack>
  );
};
