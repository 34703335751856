// @mui
import { Box, Card, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import NextLink from 'next/link';
import { Background, Parallax } from 'react-parallax';
import NextSanityImage from 'src/components/NextSanityImage';
import { useResponsive } from 'src/hooks';
// components

// ----------------------------------------------------------------------

const RootStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LandingIntroduction() {
  const isMobile = useResponsive('down', 'md');

  return (
    <RootStyle>
      <Container>
        <Grid
          container
          spacing={6}
          alignItems={{ md: 'center' }}
          justifyContent={{ md: 'space-between' }}
        >
          <Grid item xs={12} md={6} lg={5} display={{ xs: 'none', md: 'block' }}>
            <Card sx={{ overflow: 'hidden' }}>
              <Parallax strength={300} style={{ width: '100%', height: '80vh' }}>
                <Background>
                  <Box width="70vw" height="100vh">
                    <NextSanityImage
                      src="image-4cbf703b0a360dbdbe1d5264a1198ab5aecae555-3073x2048-jpg"
                      sizes="(max-width: 800px) 50vw, 1000px"
                      alt="daglig-leder"
                      sx={{ height: 1 }}
                    />
                  </Box>
                </Background>
              </Parallax>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant="h3"
              component="h2"
              sx={{ mb: 3, textAlign: { xs: 'center', md: 'left' } }}
            >
              Elektriker i Bergen til bedrift og private hjem
            </Typography>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
                <Typography variant="subtitle1">For private</Typography>
              </Stack>
              <Typography sx={{ color: 'text.secondary' }}>
                Bergen Elektro Automasjon (eller BEA som vi kaller oss), er en elektriker i Bergen
                med et sterkt fokus på det private markedet. Vi vet at punktlighet, kundeoppfølging,
                betalingsavtaler og faste priser er viktig for dere, og vi har opparbeidet et godt
                renommé i Bergen.
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Vi er også en moderne og nytenkende elektrobedrift, som holder tritt med data, mobil
                og automatiserte løsninger. Innen belysning har vi{' '}
                <NextLink href="/aktuelt/restaurant-sumo-i-bergen" passHref>
                  <Link color="secondary"> kunnskap utover gjennomsnittet</Link>
                </NextLink>
                , og vi er blitt en ekspert på
                <NextLink href="/elektriker-tjenester/elbil-lader-hjemme" passHref>
                  <Link color="secondary"> elbilladere og ladestasjoner.</Link>
                </NextLink>
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                BEA har konkurransedyktige priser, og garanterer maks kvalitet med mye kunnskap og
                god service.
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
                <Typography variant="subtitle1">For bedriften</Typography>
              </Stack>
              <Typography sx={{ color: 'text.secondary' }}>
                BEA har siden oppstarten i 2000 utviklet seg til å bli en ledende elektrobedrift i
                Bergen og omegn. BEA har 45 ansatte og forventer en omsetning i 2022 på ca. 80
                millioner. Vårt hovedfokus er kontinuerlig utvikling av moderne og effektive
                løsninger basert på behovene og ønskene til våre oppdragsgivere. Sammen med vår
                satsning på teknologi og avanserte styringssystemer har dette bidratt til at{' '}
                <NextLink href="/om-oss" passHref>
                  <Link color="secondary">
                    BEA i dag er en av de fremste elektroinstallatørene i Bergen.
                  </Link>
                </NextLink>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
