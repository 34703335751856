// icons
import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
import { OfferProps } from 'src/@types/offer';
// components
import { MotionViewport, varFade } from '../../components';
// routes
import Routes from '../../routes';
import { OfferItem } from '../offers';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.grey[200],
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  offers: OfferProps[];
};

export default function LandingOffers2({ offers }: Props) {
  return (
    <RootStyle>
      <Container>
        <Stack
          sx={{
            textAlign: { xs: 'center', md: 'unset' },
          }}
          component={MotionViewport}
        >
          <Typography variants={varFade().in} component={m.h2} variant="h2">
            Aktuelle kampanjer
          </Typography>
        </Stack>
        <Stack sx={{ py: { xs: 8, md: 10 } }} spacing={3}>
          <OfferItem large key={offers[0]._id} offer={offers[0]} />
          <Box
            sx={{
              display: 'grid',
              gap: { xs: 4, md: 3 },
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
            }}
          >
            {offers.slice(1).map((offer) => (
              <OfferItem key={offer._id} offer={offer} />
            ))}
          </Box>
        </Stack>

        <Stack alignItems={{ xs: 'center', md: 'flex-end' }}>
          <NextLink href={Routes.offers} passHref>
            <Button size="large" variant="contained">
              Se alle kampanjer
            </Button>
          </NextLink>
        </Stack>
      </Container>
    </RootStyle>
  );
}
